import React, { useEffect, useState } from 'react';
import {
  Container, Jumbotron, Col, Row,
} from 'react-bootstrap';

// TODO: Change to reflect login status
function HeroSection(props) {
  const [offsetBottom, setOffsetBottom] = useState(0);

  useEffect(() => {
    if (window.screen.width > 991) { window.addEventListener('scroll', parallaxShift); }
  }, []);

  const parallaxShift = () => {
    setOffsetBottom(window.pageYOffset);
  };

  return (
    <div className="block-31">
      <Jumbotron fluid id="hero-section">
        <Container>

          <div id="background-image" style={{ bottom: -offsetBottom / 2 }}>
            {/* <embed height="0" width="0"
  src="http://youtube.googleapis.com/v/VIDEO_ID&autoplay=1&loop=1" />
                        <div style = {{height: "100%", width: "100%",position: "absolute", top: "0"}}>
                            <iframe frameborder="0" height="100%" width="100%"
                                // src="https://www.youtube.com/embed/VVaYEnZUNHI?autoplay=1">
                                src = "https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1">
                            </iframe>
                        </div> */}
            <img
              src={props.image}
              height="100%"
              alt="hero-image"
            />
          </div>

          <div className="hero-content">
            <Row className="justify-content-center align-items-center text-center">
              <Col md={8}>
                {props.topSpace}
                <br />
                <h1>{props.title}</h1>
                <h2>{props.description}</h2>
                {props.otherContent}
              </Col>
            </Row>
          </div>
        </Container>
      </Jumbotron>
    </div>

  );
}

export default HeroSection;
